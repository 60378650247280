import axios from 'axios'

export const sendEmailContact = async (newdata) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}send-email`,
      { newdata }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}
