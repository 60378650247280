import React, { useState, useEffect } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { Link } from 'react-scroll'

const Navbar = () => {
  const [nav, setNav] = useState(false)
  const [scroll, setScroll] = useState(false)

  const handleNav = () => {
    setNav(!nav)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      className={
        scroll
          ? 'fixed top-0 left-0 w-full h-14 z-50 bg_color_new transition-all duration-500'
          : 'flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white transition-all duration-300'
      }
    >
      <div className="flex justify-between items-center w-full max-w-[1240px] mx-auto px-4">
        <h1 className="text-xl lg:text-3xl font-bold text-white uppercase">
          Transpacific line
        </h1>
        <ul className="hidden md:flex cursor-pointer">
          <Link
            to="home"
            smooth={true}
            duration={1000}
            offset={-70}
            activeClass="active"
            spy={true}
            className="hover:text-white"
          >
            <li className="p-4 uppercase font-bold">Home</li>
          </Link>

          <Link
            to="about"
            smooth={true}
            duration={300}
            offset={-70}
            activeClass="active"
            spy={true}
            className="hover:text-white"
          >
            <li className="p-4 uppercase font-bold">About</li>
          </Link>

          <Link
            to="service"
            smooth={true}
            duration={300}
            offset={-70}
            activeClass="active"
            spy={true}
            className="hover:text-white"
          >
            <li className="p-4 uppercase font-bold">Service</li>
          </Link>

          <Link
            to="schedule"
            smooth={true}
            duration={300}
            offset={-70}
            activeClass="active"
            spy={true}
            className="hover:text-white"
          >
            <li className="p-4 uppercase font-bold">Schedule</li>
          </Link>

          <Link
            to="tracking"
            smooth={true}
            duration={300}
            offset={-70}
            activeClass="active"
            spy={true}
            className="hover:text-white"
          >
            <li className="p-4 uppercase font-bold">Tracking</li>
          </Link>

          <Link
            to="contact"
            smooth={true}
            duration={300}
            offset={-70}
            activeClass="active"
            spy={true}
            className="hover:text-white"
          >
            <li className="p-4 uppercase font-bold">Contact</li>
          </Link>
        </ul>
        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>
      </div>

      <ul
        className={
          nav
            ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-sky-900 bg_color_new ease-in-out duration-500'
            : 'ease-in-out duration-500 fixed left-[-100%]'
        }
      >
        <h1 className="text-2xl font-bold text-white m-4">Transpacific line</h1>
        <Link
          to="home"
          smooth={true}
          duration={1000}
          offset={-70}
          activeClass="active"
          spy={true}
        >
          <li className="p-4 uppercase font-bold">Home</li>
        </Link>

        <Link
          to="about"
          smooth={true}
          duration={300}
          offset={-70}
          activeClass="active"
          spy={true}
        >
          <li className="p-4 uppercase font-bold">About</li>
        </Link>

        <Link
          to="service"
          smooth={true}
          duration={300}
          offset={-70}
          activeClass="active"
          spy={true}
        >
          <li className="p-4 uppercase font-bold">Service</li>
        </Link>

        <Link
          to="schedule"
          smooth={true}
          duration={300}
          offset={-70}
          activeClass="active"
          spy={true}
        >
          <li className="p-4 uppercase font-bold">Schedule</li>
        </Link>

        <Link
          to="tracking"
          smooth={true}
          duration={300}
          offset={-70}
          activeClass="active"
          spy={true}
        >
          <li className="p-4 uppercase font-bold">Tracking</li>
        </Link>

        <Link
          to="contact"
          smooth={true}
          duration={300}
          offset={-70}
          activeClass="active"
          spy={true}
        >
          <li className="p-4 uppercase font-bold">Contact</li>
        </Link>
      </ul>
    </div>
  )
}

export default Navbar
