import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import { useStateContext } from '../components/contexts/ContextProvider'
import AdminSidebar from '../components/sidebar/AdminSidebar'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import {
  CheckCircleFilled,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import BarLoader from 'react-spinners/BarLoader'
import Select from 'react-dropdown-select'
import {
  addCategory,
  deleteCategory,
  getAllCategories,
  updateCategory,
} from '../components/functions/category'
import Navbar from '../components/Navbar'
import NavbarAdmin from '../components/nav/Navbar'
import {
  addContainerShippingDetails,
  addShippingBL,
  addShippingDetails,
  deleteBlDetails,
  deleteContainer,
  deleteShippingBl,
  getAllShippings,
  getBlDetailsById,
  updateContainerDetails,
  updateShippingDetails,
} from '../components/functions/shipping'
const cancelIcon = require('../assets/cancel-icon.png')

const AdminUpdateShipping = () => {
  const { user } = useSelector((state) => ({ ...state }))
  const { blId } = useParams()
  const [userData, setUserData] = useState()
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const { activeMenu } = useStateContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [categories, setCategories] = useState([])

  const [editIsOpen, setEditIsOpen] = useState(false)

  const [editIsOpenContainer, setEditIsOpenContainer] = useState(false)

  const [showDataModal, setShowDataModal] = useState(false)
  const [showDataContainerModal, setShowDataContainerModal] = useState(false)

  const [loadingData, setLoadingData] = useState(false)

  const [selectedDataEdit, setSelectedDataEdit] = useState(null)
  const [selectedDataEditContainer, setSelectedDataEditContainer] =
    useState(null)

  const [data, setData] = useState([])
  const [successData, setSuccessData] = useState('')
  const [errorData, setErrorData] = useState('')

  const [vesselStatus, setVesselStatus] = useState([
    { id: 1, value: 'SEA PORT' },
    { id: 2, value: 'CARGO ON BOARD' },
    { id: 3, value: 'HIGH SEA' },
    { id: 4, value: 'STOP OVER' },
    { id: 5, value: 'ROLLED OVER' },
    { id: 6, value: 'COMPLETE' },
    { id: 7, value: 'ARRIVED' },
  ])

  const [selectedVessel, setSelectedVessel] = useState([])

  const loadCategories = () => {
    setLoading(true)
    setError('')
    setSuccess('')
    getAllCategories(user?.token)
      .then((res) => {
        if (res === 'No categories found.') {
          setLoading(false)
          return setError('No categories found')
        }
        if (res.status === 'ok') {
          setCategories(res.data.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        setLoading(false)
        console.log(err)
      })
  }

  const loadData = () => {
    setLoading(true)
    setError('')
    setSuccess('')
    getBlDetailsById(blId)
      .then((res) => {
        if (res === 'No bl number found.') {
          setLoading(false)
          return setError('No BL found')
        }
        if (res.status === 'ok') {
          setData(res.data)

          setLoading(false)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        setLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    loadData()
    loadCategories()
  }, [])

  const closeModal = () => {
    setShowDataModal(false)
  }

  const closeContainerModal = () => {
    setShowDataContainerModal(false)
  }

  const closeContainerEditModal = () => {
    setEditIsOpenContainer(false)
  }

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  const {
    register: registerEditCon,
    handleSubmit: handleSubmitEditCon,
    setValue: setValuEditCon,
    formState: { errors: errosEditCon },
  } = useForm()

  const {
    register: registerData,
    formState: { errors: errorsData },
    handleSubmit: handleDataSubmit,
    reset: resetData,
    watch: watchData,
  } = useForm({
    mode: 'onBlur',
  })

  const {
    register: registerContainerData,
    formState: { errors: errorsContainerData },
    handleSubmit: handleContainerDataSubmit,
    reset: resetContainerData,
  } = useForm({
    mode: 'onBlur',
  })

  const logout = () => {
    Cookies.remove('user')
    dispatch({
      type: 'LOGOUT',
      payload: null,
    })
    navigation('/login')
  }

  const etdBool = watch('etdBool')
  const etaBool = watch('etaBool')
  const shipArrived = watch('shipArrived')
  const shipArrivedAdd = watchData('shipArrivedAdd')
  const etdBoolAdd = watchData('etdBool')
  const etaBoolAdd = watchData('etaBool')

  const handleSubmitAddDetailsForm = (data) => {
    let newdata = {
      countryOfOrigin: data.countryOfOrigin || '',
      currentLocation: data.currentLocation || '',
      etdBool: etdBoolAdd === 'true' ? true : false,
      etd: etdBoolAdd === 'false' ? moment(data.etd).format('DD-MM-YYYY') || '' : null,
      etaBool: etaBoolAdd === 'true' ? true : false,
      eta: etaBoolAdd === 'false' ? moment(data.eta).format('DD-MM-YYYY') || '' : null,
      placeOfDelivery: data.placeOfDelivery || '',
      portOfDischarge: data.portOfDischarge || '',
      portOfLoading: data.portOfLoading || '',
      status: data.status || '',
      vesselName: data.vesselName || '',
      voyageNumber: data.voyageNumber || '',
      transshipment: data.transshipment || '',
      shipArrived: shipArrivedAdd === 'true' ? true : false,
    }

    if (selectedVessel !== undefined) {
      newdata.vesselStatus = selectedVessel[0]
    }

    setLoadingData(true)
    addShippingDetails(blId, newdata, user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setErrorData('')
          setSuccessData('New BL Details added!')
          setTimeout(() => {
            setSuccessData('')
            loadData()
            resetData()
            setShowDataModal(false)
          }, 2000)
          setLoadingData(false)
        } else {
          setLoadingData(false)
          setErrorData(res)
        }
      })
      .catch((err) => {
        setLoadingData(false)
      })
  }

  const handleSubmitAddContainerForm = (data) => {
    let newdata = {
      containerNumber: data.containerNumber || '',
      lastActivity: data.lastActivity || '',
      sealNumber: data.sealNumber || '',
      category: data.category || '',
      weight: data.weight || '',
      volume: data.volume || '',
      size: data.size || '',
    }
    setLoadingData(true)
    addContainerShippingDetails(blId, newdata, user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setErrorData('')
          setSuccessData('New Container Details added!')
          setTimeout(() => {
            setSuccessData('')
            loadData()
            resetContainerData()
            setShowDataContainerModal(false)
          }, 2000)
          setLoadingData(false)
        } else {
          setLoadingData(false)
          setErrorData(res)
        }
      })
      .catch((err) => {
        setLoadingData(false)
      })
  }

  const handleRemove = async (title, id) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setLoading(true)
      const blContainerId = id
      try {
        await deleteContainer(blId, blContainerId, user.token)
        toast.success(`"${title}" is deleted`)
        setLoading(false)
        window.location.reload()
      } catch (error) {
        setLoading(false)
        toast.error(error.response.data.message)
      }
    }
  }

  const handleRemoveDetails = async (id) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setLoading(true)
      const blDetailsId = id
      try {
        await deleteBlDetails(blId, blDetailsId, user.token)
        toast.success(`"Details is deleted`)
        setLoading(false)
        window.location.reload()
      } catch (error) {
        setLoading(false)
        toast.error(error.response.data.message)
      }
    }
  }

  const closeEditModal = () => {
    setEditIsOpen(false)
    setSelectedDataEdit(null)
  }

  const handleEdit = (id) => {
    const dataFound = data?.blDetails?.find((data) => data._id === id)

    setSelectedDataEdit(dataFound)

    setEditIsOpen(true)
  }

  useEffect(() => {
    if (selectedDataEdit) {
      setValue('countryOfOrigin', selectedDataEdit?.countryOfOrigin)
      setValue('currentLocation', selectedDataEdit?.currentLocation)
      setValue(
        'eta',
        moment(selectedDataEdit?.eta, 'DD-MM-YYYY').format('YYYY-MM-DD')
      )
      setValue(
        'etd',
        moment(selectedDataEdit?.etd, 'DD-MM-YYYY').format('YYYY-MM-DD')
      )
      setValue('placeOfDelivery', selectedDataEdit?.placeOfDelivery)
      setValue('portOfDischarge', selectedDataEdit?.portOfDischarge)
      setValue('portOfLoading', selectedDataEdit?.portOfLoading)
      setValue('vesselName', selectedDataEdit?.vesselName)
      setValue('voyageNumber', selectedDataEdit?.voyageNumber)
      setValue('status', selectedDataEdit?.status)
      setValue('transshipment', selectedDataEdit?.transshipment)
      setValue('shipArrived', selectedDataEdit?.shipArrived?.toString())
      setValue('etdBool', selectedDataEdit?.etdBool?.toString())
      setValue('etaBool', selectedDataEdit?.etaBool?.toString())

      const gotVessel = vesselStatus.find(
        (vs) => vs.id === selectedDataEdit?.vesselStatus?.id
      )
      if (gotVessel) {
        setSelectedVessel([gotVessel])
      } else {
        setSelectedVessel([])
      }
    }
  }, [selectedDataEdit, setValue, setSelectedVessel, vesselStatus])

  const editDataBlDetails = (getData) => {
    let newdata = {
      countryOfOrigin: getData.countryOfOrigin || '',
      currentLocation: getData.currentLocation || '',
      etdBool: etdBool === 'true' ? true : false,
      etd: etdBool === 'false' ? moment(getData.etd).format('DD-MM-YYYY') || '' : null,
      etaBool: etaBool === 'true' ? true : false,
      eta: etaBool === 'false' ? moment(getData.eta).format('DD-MM-YYYY') || '' : null,
      placeOfDelivery: getData.placeOfDelivery || '',
      portOfDischarge: getData.portOfDischarge || '',
      portOfLoading: getData.portOfLoading || '',
      status: getData.status || '',
      vesselName: getData.vesselName || '',
      voyageNumber: getData.voyageNumber || '',
      transshipment: getData.transshipment || '',
      shipArrived: shipArrived === 'true' ? true : false,
    }

    if (selectedVessel !== undefined) {
      newdata.vesselStatus = selectedVessel[0]
    }

    const blDetailsId = selectedDataEdit?._id

    setLoadingData(true)
    updateShippingDetails(blId, newdata, blDetailsId, user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setErrorData('')
          setSuccessData(`${data?.blNumber} updated`)
          setTimeout(() => {
            setSuccessData('')
            loadData()
            resetData()
            setEditIsOpen(false)
          }, 2000)
          setLoadingData(false)
        } else {
          setLoadingData(false)
          setErrorData(res)
        }
      })
      .catch((err) => {
        setLoadingData(false)
      })
  }

  const handleEditCon = (id) => {
    const dataFound = data?.containerDetails?.find((data) => data?._id === id)

    setSelectedDataEditContainer(dataFound)

    console.log(dataFound)

    setEditIsOpenContainer(true)
  }

  useEffect(() => {
    if (selectedDataEditContainer) {
      setValuEditCon(
        'containerNumber',
        selectedDataEditContainer.containerNumber
      )

      setValuEditCon('lastActivity', selectedDataEditContainer?.lastActivity)
      setValuEditCon('sealNumber', selectedDataEditContainer?.sealNumber)
      setValuEditCon('category', selectedDataEditContainer?.category?._id)
      setValuEditCon('weight', selectedDataEditContainer?.weight)
      setValuEditCon('size', selectedDataEditContainer?.size)
      setValuEditCon('volume', selectedDataEditContainer?.volume)
    }
  }, [selectedDataEditContainer, setValuEditCon])

  const editDataContainerDetails = (getData) => {
    let newdata = {
      containerNumber: getData.containerNumber || '',
      lastActivity: getData.lastActivity || '',
      sealNumber: getData.sealNumber || '',
      etd: getData.etd || '',
      category: getData.category || '',
      weight: getData.weight || '',
      volume: getData.volume || '',
      size: getData.size || '',
    }

    const blContainerId = selectedDataEditContainer?._id

    setLoadingData(true)
    updateContainerDetails(blId, newdata, blContainerId, user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setErrorData('')
          setSuccessData(`${data?.blNumber} updated`)
          setTimeout(() => {
            setSuccessData('')
            loadData()
            setEditIsOpenContainer(false)
          }, 2000)
          setLoadingData(false)
        } else {
          setLoadingData(false)
          setErrorData(res)
        }
      })
      .catch((err) => {
        setLoadingData(false)
      })
  }

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
          <NavbarAdmin />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl custom_design">
          <p className="text-2xl tracking-tight text-slate-600">
            <span className="font-bold">Update Shipping</span>
          </p>
          {loading && (
            <div className="flex justify-center items-center">
              <BarLoader color="#004a93" loading={loading} size={20} />
            </div>
          )}
          {success && (
            <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
              <CheckCircleFilled
                style={{
                  fontSize: '22px',
                  color: '#50C878',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />
              {success}
            </h5>
          )}
          {error && (
            <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
              <ExclamationCircleOutlined
                style={{
                  fontSize: '20px',
                  color: '#FAA0A0',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />{' '}
              {error}
            </h5>
          )}

          <br />

          {editIsOpenContainer && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50 overflow-y-scroll">
              <div className="bg-white rounded-lg shadow-lg sm:w-4/5 md:w-1/2 pb-2 overflow-y-auto max-h-[100vh]">
                <div className="bg-gray-100 border-b px-4 py-6 flex justify-between items-center rounded-lg">
                  <h3 className="font-semibold text-xl text-stone-600">
                    Update Container Details
                  </h3>

                  <div className="flex">
                    <button
                      onClick={closeContainerEditModal}
                      className="text-black close-modal"
                    >
                      <img src={cancelIcon} alt="Cancel" />
                    </button>
                  </div>
                </div>

                <div className="p-6">
                  {loadingData && (
                    <div className="flex justify-center items-center">
                      <BarLoader
                        color="#004a93"
                        loading={loadingData}
                        size={20}
                      />
                    </div>
                  )}

                  {successData && (
                    <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                      <CheckCircleFilled
                        style={{
                          fontSize: '22px',
                          color: '#50C878',
                          marginRight: '5px',
                          position: 'relative',
                          top: '-3px',
                        }}
                      />
                      {successData}
                    </h5>
                  )}

                  {errorData && (
                    <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                      <ExclamationCircleOutlined
                        style={{
                          fontSize: '20px',
                          color: '#FAA0A0',
                          marginRight: '5px',
                          position: 'relative',
                          top: '-3px',
                        }}
                      />{' '}
                      {errorData}
                    </h5>
                  )}

                  <form
                    onSubmit={handleSubmitEditCon(editDataContainerDetails)}
                  >
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="containerNumber"
                      >
                        Container Number
                      </label>
                      <input
                        {...registerEditCon('containerNumber', {
                          required: 'Container number is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="containerNumber"
                        type="text"
                        placeholder="Container Number"
                      />

                      {errorsContainerData.containerNumber && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.containerNumber.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="sealNumber"
                      >
                        Seal Number
                      </label>
                      <input
                        {...registerEditCon('sealNumber', {
                          required: 'Seal number is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="sealNumber"
                        type="text"
                        placeholder="Seal Number"
                      />
                      {errorsContainerData.sealNumber && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.sealNumber.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="category"
                      >
                        Category of Goods
                      </label>
                      <select
                        {...registerEditCon('category', {
                          required: 'Category is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="category"
                      >
                        <option value="">Select a category</option>
                        {categories?.map((category) => (
                          <option key={category._id} value={category?._id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      {errorsContainerData.category && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.category.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="weight"
                      >
                        Weight
                      </label>
                      <input
                        {...registerEditCon('weight', {
                          required: 'Weight is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="weight"
                        type="text"
                        placeholder="Weight"
                      />
                      {errorsContainerData.weight && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.weight.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="size"
                      >
                        Size
                      </label>
                      <input
                        {...registerEditCon('size', {
                          required: 'Size is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="size"
                        type="text"
                        placeholder="size"
                      />
                      {errorsContainerData.size && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.size.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="volume"
                      >
                        Volume
                      </label>
                      <input
                        {...registerEditCon('volume', {
                          required: 'Volume	is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="volume"
                        type="text"
                        placeholder="Volume"
                      />
                      {errorsContainerData.volume && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.volume.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="lastActivity"
                      >
                        Last Activity
                      </label>
                      <textarea
                        {...registerEditCon('lastActivity', {
                          required: 'Last Activity is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="lastActivity"
                        placeholder="Enter the last activity"
                      ></textarea>
                      {errorsContainerData.lastActivity && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.lastActivity.message}
                        </p>
                      )}
                    </div>

                    <div className="flex justify-start mt-10">
                      <button
                        className={`bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 rounded mr-10 px-10 ${
                          loadingData && 'ActionButton'
                        }`}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        onClick={closeContainerEditModal}
                        className=" text-blue-800 font-bold py-2 px-10 rounded border-blue-800 border-1"
                        disabled={loadingData}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {editIsOpen && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50">
              <div className="bg-white rounded-lg shadow-lg sm:w-4/5 md:w-1/2 pb-2 overflow-y-auto max-h-[100vh]">
                <div className="bg-gray-100 border-b px-4 py-6 flex justify-between items-center rounded-lg">
                  <h3 className="font-semibold text-xl text-stone-600">
                    Update BL Details
                  </h3>

                  <div className="flex">
                    <button
                      onClick={closeEditModal}
                      className="text-black close-modal"
                    >
                      <img src={cancelIcon} alt="Cancel" />
                    </button>
                  </div>
                </div>

                <div className="p-6">
                  {loadingData && (
                    <div className="flex justify-center items-center">
                      <BarLoader
                        color="#004a93"
                        loading={loadingData}
                        size={20}
                      />
                    </div>
                  )}

                  {successData && (
                    <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                      <CheckCircleFilled
                        style={{
                          fontSize: '22px',
                          color: '#50C878',
                          marginRight: '5px',
                          position: 'relative',
                          top: '-3px',
                        }}
                      />
                      {successData}
                    </h5>
                  )}

                  {errorData && (
                    <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                      <ExclamationCircleOutlined
                        style={{
                          fontSize: '20px',
                          color: '#FAA0A0',
                          marginRight: '5px',
                          position: 'relative',
                          top: '-3px',
                        }}
                      />{' '}
                      {errorData}
                    </h5>
                  )}

                  <form onSubmit={handleSubmit(editDataBlDetails)}>
                    <div className="mb-4">
                      <label className="text-slate-600">Vessel status</label>
                      <Select
                        name="select"
                        options={vesselStatus}
                        labelField="value"
                        valueField="id"
                        onChange={(value) => setSelectedVessel(value)}
                        color="#5ac8fa"
                        values={selectedVessel}
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="transshipment"
                      >
                        Transshipment
                      </label>
                      <input
                        {...register('transshipment')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="transshipment"
                        type="text"
                        placeholder="Transshipment"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="text-slate-600">Ship arrived?</label>
                      <div className="flex items-center mt-1">
                        <label htmlFor="shipArrivedYes" className="mr-4">
                          <input
                            {...register('shipArrived')}
                            type="radio"
                            value="true"
                            id="shipArrivedYes"
                            className="mr-2"
                            checked={watch('shipArrived') === 'true'}
                          />
                          Yes
                        </label>
                        <label htmlFor="shipArrivedNo">
                          <input
                            {...register('shipArrived')}
                            type="radio"
                            value="false"
                            id="shipArrivedNo"
                            className="mr-2"
                            checked={watch('shipArrived') === 'false'}
                          />
                          No
                        </label>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="countryOfOrigin"
                      >
                        Country of origin
                      </label>
                      <input
                        {...register('countryOfOrigin')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="countryOfOrigin"
                        type="text"
                        placeholder="Country of origin"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="etd"
                      >
                        ETD
                      </label>

                    <div className="mb-4">
                      <label className="text-slate-600">TBA (To be announced)</label>
                      <div className="flex items-center mt-1">
                        <label htmlFor="etdBoolYes" className="mr-4">
                          <input
                            {...register('etdBool')}
                            type="radio"
                            value="true"
                            id="etdBoolYes"
                            className="mr-2"
                            checked={watch('etdBool') === 'true'}
                          />
                          Yes
                        </label>
                        <label htmlFor="etdBoolNo">
                          <input
                            {...register('etdBool')}
                            type="radio"
                            value="false"
                            id="etdBoolNo"
                            className="mr-2"
                            checked={watch('etdBool') === 'false'}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {watch('etdBool') === 'false' && (
                        <input
                          {...register('etd')}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="etd"
                          type="date"
                          placeholder="ETD"
                          required
                        />
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="vesselName"
                      >
                        Vessel Name
                      </label>
                      <input
                        {...register('vesselName')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vesselName"
                        type="text"
                        placeholder="Vessel name"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="voyageNumber"
                      >
                        Voyage number
                      </label>
                      <input
                        {...register('voyageNumber')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="voyageNumber"
                        type="text"
                        placeholder="Voyage number"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="portOfLoading"
                      >
                        Port of Loading
                      </label>
                      <input
                        {...register('portOfLoading')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfLoading"
                        type="text"
                        placeholder="Port of Loading"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="portOfDischarge"
                      >
                        Port of Discharge
                      </label>
                      <input
                        {...register('portOfDischarge')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfDischarge"
                        type="text"
                        placeholder="Port of Discharge	"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="placeOfDelivery"
                      >
                        Place of Delivery
                      </label>
                      <input
                        {...register('placeOfDelivery')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="placeOfDelivery"
                        type="text"
                        placeholder="Place of Delivery"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="currentLocation"
                      >
                        Current Location
                      </label>
                      <input
                        {...register('currentLocation')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="currentLocation"
                        type="text"
                        placeholder="Current Location"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="eta"
                      >
                        ETA
                      </label>


                      <div className="mb-4">
                      <label className="text-slate-600">TBA (To be announced)</label>
                      <div className="flex items-center mt-1">
                        <label htmlFor="etaBoolYes" className="mr-4">
                          <input
                            {...register('etaBool')}
                            type="radio"
                            value="true"
                            id="etaBoolYes"
                            className="mr-2"
                            checked={watch('etaBool') === 'true'}
                          />
                          Yes
                        </label>
                        <label htmlFor="etaBoolNo">
                          <input
                            {...register('etaBool')}
                            type="radio"
                            value="false"
                            id="etaBoolNo"
                            className="mr-2"
                            checked={watch('etaBool') === 'false'}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    {watch('etaBool') === 'false' && (
                      <input
                        {...register('eta')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="eta"
                        type="date"
                        placeholder="ETA"
                        required
                      />
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="status"
                      >
                        Status
                      </label>
                      <input
                        {...register('status')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="status"
                        type="text"
                        placeholder="Status"
                      />
                    </div>

                    <div className="flex justify-start mt-10">
                      <button
                        className={`bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 rounded mr-10 px-10 ${
                          loadingData && 'ActionButton'
                        }`}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        onClick={closeEditModal}
                        className=" text-blue-800 font-bold py-2 px-10 rounded border-blue-800 border-1"
                        disabled={loadingData}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {showDataModal && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50 overflow-y-scroll">
              <div className="bg-white rounded-lg shadow-lg sm:w-4/5 md:w-1/2 pb-2 overflow-y-auto max-h-[100vh]">
                <div className="bg-gray-100 border-b px-4 py-6 flex justify-between items-center rounded-lg">
                  <h3 className="font-semibold text-xl text-stone-600">
                    Add BL Details
                  </h3>

                  <div className="flex">
                    <button
                      onClick={closeModal}
                      className="text-black close-modal"
                    >
                      <img src={cancelIcon} alt="Cancel" />
                    </button>
                  </div>
                </div>

                <div className="p-6">
                  {loadingData && (
                    <div className="flex justify-center items-center">
                      <BarLoader
                        color="#004a93"
                        loading={loadingData}
                        size={20}
                      />
                    </div>
                  )}

                  {successData && (
                    <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                      <CheckCircleFilled
                        style={{
                          fontSize: '22px',
                          color: '#50C878',
                          marginRight: '5px',
                          position: 'relative',
                          top: '-3px',
                        }}
                      />
                      {successData}
                    </h5>
                  )}

                  {errorData && (
                    <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                      <ExclamationCircleOutlined
                        style={{
                          fontSize: '20px',
                          color: '#FAA0A0',
                          marginRight: '5px',
                          position: 'relative',
                          top: '-3px',
                        }}
                      />{' '}
                      {errorData}
                    </h5>
                  )}

                  <form onSubmit={handleDataSubmit(handleSubmitAddDetailsForm)}>
                    <div className="mb-4">
                      <label className="text-slate-600">Vessel status</label>
                      <Select
                        name="select"
                        options={vesselStatus}
                        labelField="value"
                        valueField="id"
                        onChange={(value) => setSelectedVessel(value)}
                        color="#5ac8fa"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="transshipment"
                      >
                        Transshipment
                      </label>
                      <input
                        {...registerData('transshipment')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="transshipment"
                        type="text"
                        placeholder="Transshipment"
                      />
                    </div>
                    <div className="mb-4">
                      <label className="text-slate-600">Ship arrived?</label>
                      <div className="flex items-center mt-1">
                        <label htmlFor="shipArrivedYes" className="mr-4">
                          <input
                            {...registerData('shipArrived')}
                            type="radio"
                            value="true"
                            id="shipArrivedYes"
                            className="mr-2"
                          />
                          Yes
                        </label>
                        <label htmlFor="shipArrivedNo">
                          <input
                            {...registerData('shipArrived')}
                            type="radio"
                            value="false"
                            id="shipArrivedNo"
                            className="mr-2"
                            checked
                          />
                          No
                        </label>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="countryOfOrigin"
                      >
                        Country of origin
                      </label>
                      <input
                        {...registerData('countryOfOrigin')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="countryOfOrigin"
                        type="text"
                        placeholder="Country of origin"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="etd"
                      >
                        ETD
                      </label>

                      <div className="mb-4">
                      <label className="text-slate-600">TBA (To be announced)</label>
                      <div className="flex items-center mt-1">
                        <label htmlFor="etdBoolYes" className="mr-4">
                          <input
                            {...registerData('etdBool')}
                            type="radio"
                            value="true"
                            id="etdBoolYes"
                            className="mr-2"
                           
                          />
                          Yes
                        </label>
                        <label htmlFor="etdBoolNo">
                          <input
                            {...registerData('etdBool')}
                            type="radio"
                            value="false"
                            id="etdBoolNo"
                            className="mr-2"
                            
                          />
                          No
                        </label>
                        </div>
                      </div>
                      {watchData('etdBool') === 'false' && (
                        <input
                          {...registerData('etd')}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="etd"
                          type="date"
                          placeholder="ETD"
                          required
                        />
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="vesselName"
                      >
                        Vessel Name
                      </label>
                      <input
                        {...registerData('vesselName')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="vesselName"
                        type="text"
                        placeholder="Vessel name"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="voyageNumber"
                      >
                        Voyage number
                      </label>
                      <input
                        {...registerData('voyageNumber')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="voyageNumber"
                        type="text"
                        placeholder="Voyage number"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="portOfLoading"
                      >
                        Port of Loading
                      </label>
                      <input
                        {...registerData('portOfLoading')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfLoading"
                        type="text"
                        placeholder="Port of Loading"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="portOfDischarge"
                      >
                        Port of Discharge
                      </label>
                      <input
                        {...registerData('portOfDischarge')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="portOfDischarge"
                        type="text"
                        placeholder="Port of Discharge	"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="placeOfDelivery"
                      >
                        Place of Delivery
                      </label>
                      <input
                        {...registerData('placeOfDelivery')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="placeOfDelivery"
                        type="text"
                        placeholder="Place of Delivery"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="currentLocation"
                      >
                        Current Location
                      </label>
                      <input
                        {...registerData('currentLocation')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="currentLocation"
                        type="text"
                        placeholder="Current Location"
                      />
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="eta"
                      >
                        ETA
                      </label>

                      <div className="mb-4">
                      <label className="text-slate-600">TBA (To be announced)</label>
                      <div className="flex items-center mt-1">
                        <label htmlFor="etaBoolYes" className="mr-4">
                          <input
                            {...registerData('etaBool')}
                            type="radio"
                            value="true"
                            id="etaBoolYes"
                            className="mr-2"
                           
                          />
                          Yes
                        </label>
                        <label htmlFor="etaBoolNo">
                          <input
                            {...registerData('etaBool')}
                            type="radio"
                            value="false"
                            id="etaBoolNo"
                            className="mr-2"
                            
                          />
                          No
                        </label>
                        </div>
                      </div>
                      {watchData('etaBool') === 'false' && (
                        <input
                          {...registerData('eta')}
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="etd"
                          type="date"
                          placeholder="ETD"
                          required
                        />
                      )}
                      
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="status"
                      >
                        Status
                      </label>
                      <input
                        {...registerData('status')}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="status"
                        type="text"
                        placeholder="Status"
                      />
                    </div>

                    <div className="flex justify-start mt-10">
                      <button
                        className={`bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 rounded mr-10 px-10 ${
                          loadingData && 'ActionButton'
                        }`}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        onClick={closeModal}
                        className=" text-blue-800 font-bold py-2 px-10 rounded border-blue-800 border-1"
                        disabled={loadingData}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          {showDataContainerModal && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50 overflow-y-scroll">
              <div className="bg-white rounded-lg shadow-lg sm:w-4/5 md:w-1/2 pb-2 overflow-y-auto max-h-[100vh]">
                <div className="bg-gray-100 border-b px-4 py-6 flex justify-between items-center rounded-lg">
                  <h3 className="font-semibold text-xl text-stone-600">
                    Add Container Details
                  </h3>

                  <div className="flex">
                    <button
                      onClick={closeContainerModal}
                      className="text-black close-modal"
                    >
                      <img src={cancelIcon} alt="Cancel" />
                    </button>
                  </div>
                </div>

                <div className="p-6">
                  {loadingData && (
                    <div className="flex justify-center items-center">
                      <BarLoader
                        color="#004a93"
                        loading={loadingData}
                        size={20}
                      />
                    </div>
                  )}

                  {successData && (
                    <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                      <CheckCircleFilled
                        style={{
                          fontSize: '22px',
                          color: '#50C878',
                          marginRight: '5px',
                          position: 'relative',
                          top: '-3px',
                        }}
                      />
                      {successData}
                    </h5>
                  )}

                  {errorData && (
                    <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                      <ExclamationCircleOutlined
                        style={{
                          fontSize: '20px',
                          color: '#FAA0A0',
                          marginRight: '5px',
                          position: 'relative',
                          top: '-3px',
                        }}
                      />{' '}
                      {errorData}
                    </h5>
                  )}

                  <form
                    onSubmit={handleContainerDataSubmit(
                      handleSubmitAddContainerForm
                    )}
                  >
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="containerNumber"
                      >
                        Container Number
                      </label>
                      <input
                        {...registerContainerData('containerNumber', {
                          required: 'Container number is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="containerNumber"
                        type="text"
                        placeholder="Container Number"
                      />

                      {errorsContainerData.containerNumber && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.containerNumber.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="sealNumber"
                      >
                        Seal Number
                      </label>
                      <input
                        {...registerContainerData('sealNumber', {
                          required: 'Seal number is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="sealNumber"
                        type="text"
                        placeholder="Seal Number"
                      />
                      {errorsContainerData.sealNumber && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.sealNumber.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="category"
                      >
                        Category of Goods
                      </label>
                      <select
                        {...registerContainerData('category', {
                          required: 'Category is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="category"
                      >
                        <option value="">Select a category</option>
                        {categories?.map((category) => (
                          <option key={category?._id} value={category?._id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      {errorsContainerData.category && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.category.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="weight"
                      >
                        Weight
                      </label>
                      <input
                        {...registerContainerData('weight', {
                          required: 'Weight is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="weight"
                        type="text"
                        placeholder="Weight"
                      />
                      {errorsContainerData.weight && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.weight.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="size"
                      >
                        Size
                      </label>
                      <input
                        {...registerContainerData('size', {
                          required: 'Size is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="size"
                        type="text"
                        placeholder="Size"
                      />
                      {errorsContainerData.size && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.size.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="volume"
                      >
                        Volume
                      </label>
                      <input
                        {...registerContainerData('volume', {
                          required: 'Volume	is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="volume"
                        type="text"
                        placeholder="Volume"
                      />
                      {errorsContainerData.volume && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.volume.message}
                        </p>
                      )}
                    </div>

                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="lastActivity"
                      >
                        Last Activity
                      </label>
                      <textarea
                        {...registerContainerData('lastActivity', {
                          required: 'Last Activity is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="lastActivity"
                        placeholder="Enter the last activity"
                      ></textarea>
                      {errorsContainerData.lastActivity && (
                        <p className="text-red-500 text-xs py-1">
                          {errorsContainerData.lastActivity.message}
                        </p>
                      )}
                    </div>

                    <div className="flex justify-start mt-10">
                      <button
                        className={`bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 rounded mr-10 px-10 ${
                          loadingData && 'ActionButton'
                        }`}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        onClick={closeContainerModal}
                        className=" text-blue-800 font-bold py-2 px-10 rounded border-blue-800 border-1"
                        disabled={loadingData}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="border-b-1 relative border-pos-settings"></div>

              <button
                onClick={() => setShowDataModal(true)}
                className="new_role_btn"
              >
                Add new details
              </button>

              <div className="pb-3 mt-6 bg-white">
                <div className="space-y-6">
                  <p className="text-2xl">BL NUMBER DETAILS</p>
                  <div className="overflow-scroll">
                    <table className="w-full">
                      <thead>
                        <tr className="">
                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            BL NUMBER
                          </th>
                          <th className="py-2 px-6 text-left whitespace-nowrap">
                            {data?.blNumber}
                          </th>
                        </tr>
                        <tr>
                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Country of Origin
                          </th>

                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            ETD
                          </th>
                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Vessel Name
                          </th>
                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Voyage Number
                          </th>
                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Port of Loading
                          </th>
                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Port of Discharge
                          </th>
                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Place of Delivery
                          </th>
                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Current Location
                          </th>
                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            ETA
                          </th>
                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Status
                          </th>

                          <th className="py-2 px-2 text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.blDetails?.map((dts, index) => {
                          const isEvenRow = index % 2 === 0
                          const rowClass = isEvenRow
                            ? 'bg-gray-100'
                            : 'bg-white'

                          return (
                            <tr key={dts?._id} className={rowClass}>
                              <td className="py-3 text-gray-500">
                                {dts?.countryOfOrigin}
                              </td>
                              <td className="py-3 text-gray-500">{dts?.etdBool ? 'TBA (To be announced)' : dts?.etd}</td>
                              <td className="py-3 text-gray-500">
                                {dts?.vesselName}
                              </td>
                              <td className="py-3 text-gray-500">
                                {dts?.voyageNumber}
                              </td>
                              <td className="py-3 text-gray-500">
                                {dts?.portOfLoading}
                              </td>
                              <td className="py-3 text-gray-500">
                                {dts?.portOfDischarge}
                              </td>
                              <td className="py-3 text-gray-500">
                                {dts?.placeOfDelivery}
                              </td>
                              <td className="py-3 text-gray-500">
                                {dts?.currentLocation}
                              </td>
                              <td className="py-3 text-gray-500">{dts?.etaBool ? 'TBA (To be announced)' : dts?.eta}</td>

                              <td className="py-3 text-gray-500">
                                {dts?.status}
                              </td>

                              <td className="py-3 text-gray-500 text-center flex justify-center items-center">
                                <button
                                  onClick={() => handleEdit(dts?._id)}
                                  className="px-2 py-1"
                                >
                                  <EditOutlined className="text-green-500 ml-1" />
                                </button>
                                <button
                                  onClick={() => handleRemoveDetails(dts?._id)}
                                  className="px-2 py-1"
                                >
                                  <DeleteOutlined className="text-red-500 ml-1" />
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <button
                onClick={() => setShowDataContainerModal(true)}
                className="new_role_btn"
              >
                Add new container
              </button>

              <div className="pb-3 mt-6 bg-white">
                <div className="space-y-6">
                  <p className="text-2xl">BL NUMBER CONTAINER DETAILS</p>
                  <div className="overflow-scroll">
                    <table className="w-full">
                      <thead>
                        <tr>
                          <th className="py-2  text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Container Number
                          </th>

                          <th className="py-2  text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Seal Number
                          </th>
                          <th className="py-2  text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Size
                          </th>
                          <th className="py-2  text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Category of Goods
                          </th>
                          <th className="py-2  text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Weight
                          </th>
                          <th className="py-2  text-left text-sm text-white bg-blue-500 whitespace-nowrap">
                            Volume
                          </th>

                          <th className="py-2  text-left text-sm text-white bg-blue-500 whitespace-nowrap"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.containerDetails?.map((dts, index) => {
                          const isEvenRow = index % 2 === 0
                          const rowClass = isEvenRow
                            ? 'bg-gray-100'
                            : 'bg-white'

                          return (
                            <tr key={dts?._id} className={rowClass}>
                              <td className="py-3 text-gray-500">
                                {dts?.containerNumber}
                              </td>
                              <td className="py-3 text-gray-500">
                                {dts?.sealNumber}
                              </td>
                              <td className="py-3 text-gray-500">
                                {dts?.size}
                              </td>
                              <td className="py-3 text-gray-500">
                                {dts?.category?.name}
                              </td>
                              <td className="py-3 text-gray-500">
                                {dts?.weight}
                              </td>
                              <td className="py-3 text-gray-500">
                                {dts?.volume}
                              </td>

                              <td className="py-3 text-gray-500 text-center flex justify-center items-center">
                                <button
                                  onClick={() => handleEditCon(dts?._id)}
                                  className="px-2 py-1"
                                >
                                  <EditOutlined className="text-green-500 ml-1" />
                                </button>
                                <button
                                  onClick={() =>
                                    handleRemove(dts?.containerNumber, dts?._id)
                                  }
                                  className="px-2 py-1"
                                >
                                  <DeleteOutlined className="text-red-500 ml-1" />
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminUpdateShipping
