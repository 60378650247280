import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

const Tracking = () => {
  const navigation = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    const { blNumber } = data
    navigation(`/tracking/${blNumber}`)
  }

  return (
    <div className="w-full py-16 text-white px-4 bg-slate-200" id="tracking">
      <div className="max-w-[1240px] mx-auto mb-10">
        <div className="text-center">
          <h3 className="text-3xl text-black">Tracking</h3>
          <p className="my-5 text-md text-center text-black">
            Track the progress of your shipment by entering your unique BL
            number in the field below.
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="lg:col-span-2 my-4">
        <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0">
          <input
            {...register('blNumber', {
              required: 'BL Number is required',
            })}
            className="p-3 min-w-[320px] h-full rounded-md text-black text-center"
            type="text"
            placeholder="BL NUMBER"
            style={{
              fontFamily: 'sans-serif',
            }}
          />
        </div>
        {errors.blNumber && (
          <p className="text-red-500 text-md pt-2 text-center">
            {errors.blNumber.message}
          </p>
        )}
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="bg_color_new text-white rounded-md font-medium min-w-[320px] h-full px-4 py-2"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  )
}

export default Tracking
