import React from 'react'
import Tripple from '../assets/triple.png'
import secBg from '../assets/sec-bg.jpg'
import { FaUserTie } from 'react-icons/fa'
import { TbCloudComputing } from 'react-icons/tb'
import { PiShareNetworkBold } from 'react-icons/pi'
import { MdOutlineRepeatOn } from 'react-icons/md'

const About = () => {
  const backgroundUrl = `url(${secBg})`
  return (
    <div
      className="w-full py-20 px-4 bg-fixed bg-cover bg-center overflow-hidden"
      id="about"
      style={{ backgroundImage: backgroundUrl }}
    >
      <div className="max-w-[1240px] mx-auto  grid md:grid-cols-2">
        <div className="md:pr-10">
          <h3 className="text-3xl">
            About <span className="text_color_new">Transpacific Line</span>
          </h3>

          <p className="my-5 text-md text-justify">
            Our mission is to serve our customers in the global logistics and
            transportation domain by providing creative, cost effective supply
            chain solutions that continually meet and exceed our customer's
            expectations. To provide absolute value to increase freight
            management efficiencies thus providing maximum hard and soft savings
            for our customers in the process of shipping. <br /> <br />
            TRANSPACIFIC LINE (PTE) LIMITED is known for its services in the
            NVOCC connecting South America, Central America and Carribean to Far
            East. We operate a fleet of SOC Containers more than 1000 TEUS
            within our develop tradelanes. We actively securing new slot space
            from various carriers to fullfill time-bound delivery to the Far
            East. Our main business focus mainly in the commodities, oil
            processing and food industries.
          </p>
        </div>

        <div className="flex flex-col md:flex-row sm:items-center sm:justify-center">
          <div>
            <div className="flex flex-col items-center sm:items-start sm:justify-center my-6">
              <FaUserTie size={25} color="#28abe2" />
              <h2 className="text-2xl font-bold text_color_new my-2">
                Professional
              </h2>
              <p className="text-gray-600">
                Our organization thrives on the energy and expertise of our
                talented professionals
              </p>
            </div>
            <div className="flex flex-col items-center sm:items-start sm:justify-center my-6">
              <PiShareNetworkBold size={25} color="#28abe2" />
              <h2 className="text-2xl font-bold text_color_new my-2">
                Network
              </h2>
              <p className="text-gray-600">
                Our extensive years of expertise in various industries have
                allowed us to establish a network of operators that is both
                reliable and trustworthy.
              </p>
            </div>
          </div>

          <div>
            <div className="flex flex-col items-center sm:items-start sm:justify-center my-6">
              <FaUserTie size={25} color="#28abe2" />
              <h2 className="text-2xl font-bold text_color_new my-2">
                Power of Technology
              </h2>
              <p className="text-gray-600">
                Empowered by our seamless and cutting-edge IT infrastructure, we
                streamline our operations for optimal efficiency.
              </p>
            </div>
            <div className="flex flex-col items-center sm:items-start sm:justify-center my-6">
              <TbCloudComputing size={25} color="#28abe2" />
              <h2 className="text-2xl font-bold text_color_new my-2">
                Customers
              </h2>
              <p className="text-gray-600">
                Putting customers at the forefront of our business, we
                prioritize their needs and strive to provide the best possible
                experience for every individual we serve.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
