import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const ToRedirect = () => {
  const [count, setCount] = useState(5)
  let history = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount)
    }, 1000)
    // redirect once count is equal to 0
    count === 0 && history('/')
    // cleanup
    return () => clearInterval(interval)
  }, [count, history])

  return (
    <div className="container-fluid p-5 text-center">
      <p className="text-danger h6 my-2 d-flex justify-content-center align-items-center">
        <ExclamationCircleOutlined
          style={{ fontSize: '25px', color: 'red', marginRight: '5px' }}
        />
        <span className="mt-3 block">
          Private resource! Authentication required
        </span>
      </p>
      <p className="text-muted"> Redirecting you in {count} seconds</p>
    </div>
  )
}

export default ToRedirect
