import React, { useEffect, useState } from 'react'
import Hero from '../components/Hero'
import About from '../components/About'
import Footer from '../components/Footer'
import Services from '../components/Services'
import Tracking from '../components/Tracking'
import Contact from '../components/Contact'
import Schedule from '../components/Schedule'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
const cancelIcon = require('../assets/cancel-icon.png')

const Main = () => {
  const [showDataModal, setShowDataModal] = useState(true)

  useEffect(() => {
    const isModalShown = localStorage.getItem('isModalShown');
    if (isModalShown) {
      setShowDataModal(false);
    }
  }, []);

  const navigation = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    const { blNumber } = data
    navigation(`/tracking/${blNumber}`)
  }

  const closeModal = () => {
    setShowDataModal(false)
    localStorage.setItem('isModalShown', 'true');
  }
  return (
    <div>
      {showDataModal && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50">
          <div className="bg-sky-500 rounded-lg shadow-lg sm:w-4/5 md:w-1/2 pb-2">
            <div className="bg-white border-b px-8 lg:px-4 py-6 flex justify-between items-center">
              <h3 className="font-semibold text-xl text-stone-600">
                Type your BL number to track your shipment
              </h3>

              <div className="flex">
                <button onClick={closeModal} className="text-black close-modal">
                  <img src={cancelIcon} alt="Cancel" />
                </button>
              </div>
            </div>

            <div className="p-6">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="lg:col-span-2 my-4"
              >
                <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0">
                  <input
                    {...register('blNumber', {
                      required: 'BL Number is required',
                    })}
                    className="p-3 min-w-[280px] lg:min-w-[320px] h-full rounded-md text-black text-center"
                    type="text"
                    placeholder="BL NUMBER"
                    style={{
                      fontFamily: 'sans-serif',
                    }}
                  />
                </div>
                {errors.blNumber && (
                  <p className="text-red-500 text-md pt-2 text-center">
                    {errors.blNumber.message}
                  </p>
                )}
                <div className="flex justify-center mt-4">
                  <button
                    type="submit"
                    className="bg-sky-700 text-white rounded-md font-medium min-w-[280px] lg:min-w-[320px] h-full px-4 py-2"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <Hero />
      <About />
      <Services />
      <Schedule />
      <Tracking />
      <Contact />
      <Footer />
    </div>
  )
}

export default Main
