import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './pages/Main'
import Admin from './pages/Admin'
import Login from './pages/login'
import AdminRoute from './components/routes/AdminRoute'
import NotLoggedInRoute from './components/routes/NotLoggedInRoute'
import AdminCategories from './pages/AdminCategories'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AdminShipping from './pages/AdminShipping'
import AdminUpdateShipping from './pages/AdminUpdateShipping'
import BlTrackingDetails from './pages/BlTrackingDetails'
import NotFound from './pages/NotFound/NotFound'

function App() {
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/tracking/:blId" element={<BlTrackingDetails />} />

        <Route element={<NotLoggedInRoute />}>
          <Route exact path="/login" element={<Login />} />
        </Route>

        <Route element={<AdminRoute />}>
          <Route exact path="/admin/categories" element={<AdminCategories />} />
          <Route
            exact
            path="/admin/shipping/manage-bl"
            element={<AdminShipping />}
          />

          <Route
            exact
            path="/admin/shipping/manage-bl/update-bl/:blId"
            element={<AdminUpdateShipping />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App
