import React, { useState } from 'react'
import { LiaMapMarkedAltSolid } from 'react-icons/lia'
import { MdEmail } from 'react-icons/md'
import { PiPhone } from 'react-icons/pi'
import { useForm } from 'react-hook-form'
import { sendEmailContact } from './functions/user'
import BarLoader from 'react-spinners/BarLoader'
import { CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'
const MapImage = require('../assets/trans-map.jpg')

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [successData, setSuccessData] = useState('')
  const [errorData, setErrorData] = useState('')
  const [loadingData, setLoadingData] = useState(false)

  const onSubmit = (data) => {
    const newdata = {
      name: data.name,
      email: data.email,
      subject: data.subject,
      message: data.message,
    }
    setLoadingData(true)
    sendEmailContact(newdata)
      .then((res) => {
        if (res.status === 'ok') {
          setErrorData('')
          setSuccessData('Email sent successfully')
          setTimeout(() => {
            setSuccessData('')
            reset()
          }, 2000)
          setLoadingData(false)
        } else {
          setLoadingData(false)
          setErrorData(res)
        }
      })
      .catch((err) => {
        setLoadingData(false)
      })
  }
  return (
    <div className="w-full py-16 px-4 bg-white" id="contact">
      <div className="max-w-[1240px] mx-auto mb-10">
        <div className="text-center">
          <h3 className="text-3xl text-black">Contact Us</h3>
          <p className="my-5 text-md text-center text-black">
            Feel free to reach out to our dedicated team of experts who are
            ready to assist you with any inquiries or assistance you may need.
            Your satisfaction is our top priority, and we are here to provide
            exceptional support and guidance every step of the way. Contact us
            today and experience unparalleled service and professionalism.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto">
        <div className="shadow-md p-4 md:col-span-2">
          <div className="flex justify-center items-center mb-1">
            <LiaMapMarkedAltSolid size={40} color="#28abe2" />
          </div>
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">Address</h2>
            <p className="text-gray-600 mb-4">
            481 HINKLER ROAD,
SOUTHAMPTON, SO19 6DN
UNITED KINGDOM
             
            </p>
          </div>

          <div className="map-container">
            <img src={MapImage} alt="Trans Map View" />
          </div>
        </div>

        <div className="flex flex-col">
          <div className="shadow-md p-4">
            {/* Content for the second column */}
            <div className="flex justify-center items-center mb-1">
              <MdEmail size={40} color="#28abe2" />
            </div>
            <div className="text-center">
              <h2 className="text-xl font-bold py-4 text-neutral-600">Email</h2>
              <p className="text-gray-600">info@transpacificline.com</p>
            </div>
          </div>

          <div className="shadow-md p-4">
            {/* Content for the third column */}
            <div className="flex justify-center items-center mb-1">
              <MdEmail size={40} color="#28abe2" />
            </div>
            <div className="text-center">
              <h2 className="text-xl font-bold py-4 text-neutral-600">
                Tracking enquiry
              </h2>
              <p className="text-gray-600">tracking@transpacificline.com</p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto mt-5">
        <div className="flex">
          <div className="w-1/2 mr-4 shadow-md">
            <div className="p-4 text-center">
              <h1 className="text-xl font-bold py-1 text-neutral-600">
                UK OFFICE
              </h1>
              <p className="text-gray-600 mb-4 font-sans">
                481 HINKLER ROAD, <br />
                SOUTHAMPTON, SO19 6DN <br />
                UNITED KINGDOM <br />
              </p>
            </div>
          </div>
          <div className="w-1/2 shadow-md">
            <div className="p-4 text-center">
              <h1 className="text-xl font-bold py-1 text-neutral-600">
                SINGAPORE OFFICE
              </h1>
              <p className="text-gray-600 mb-4 font-sans">
                10 Ubi Cresent <br />
                Singapore 408564
              </p>
            </div>
          </div>
        </div>
      </div>



      <h1 className="text-center text-3xl text-black my-5">Branch Office</h1>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto mt-3">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
            OAKLAND SALES OFFICE, USA
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            TRANSPACIFIC LINE <br />
            410 WEST GRAND AVE, <br />
            OAKLAND, CA 94612, <br />
            USA

            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">HOUSTON, SALES OFFICE, USA</h2>
            <p className="text-gray-600 mb-4 font-sans">
                
                TRANSPACIFIC LINE  <br />
                16666 NORTHCHASE DR, SUITE 802 <br />
                HOUSTON, TX 77060 <br />
                USA

            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
              LOS ANGELES, SALES OFFICE, USA
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
                
                TRANSPACIFIC LINE <br />
                434 E BROADWAY, LONG BEACH,  <br />
                CA 90802, USA <br />

            </p>
          </div>
        </div>
       
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto mt-3">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
            NEW YORK, SALES OFFICE, USA
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            TRANSPACIFIC LINE <br />
            371 HOES LN #100, <br />
            PISCATAWAY, NJ 08854,  <br />
            USA
            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">DUBAI OFFICE - AREA ARABIAN GULF, UAE</h2>
            <p className="text-gray-600 mb-4 font-sans">  
              TRANSPACIFIC LINE <br />
              18 - 18 65th St - GARHOUD - DUBAI <br />
              UNITED ARAB EMIRATES <br />
            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
            EUROPEAN OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            NETHERLANDS - SALES -  <br />
            TRANSPACIFIC LINE <br />
            WAALHAVAN OOSTZIJDE 85,  <br />
            3087 BM ROTTERDAM, <br />
            NETHERLANDS

            </p>
          </div>
        </div>
       
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto mt-3">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
            SHANGHAI, CHINA OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
          
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            
              TRANSPACIFIC LINE <br />
              INTERNATIONAL BUND GATEWAY <br />
              19TH FLOOR, 399 EAST CHANGZHI ROAD <br />
              200080 SHANGHAI <br />
              CHINA


            </p>
          </div>
            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">GUANGZHOU BRANCH</h2>
            <p className="text-gray-600 mb-4 font-sans">
              
              TRANSPACIFIC LINE <br />
              UNIT-10, 39-FLOOR,GUANGZHOU INTL COMM <br />
              CENTER 235 TIANHE NORTH ROAD <br />
              510610 GUANGZHOU <br />
              CHINA

            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
            COLOMBIA OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
              TRANSPACIFIC LINE <br />
              CARRERA 27  <br />
              FLOOR 21 SUITE 2104 <br />
              130001 CARTAGENA <br />
              COLOMBIA

            </p>
          </div>
        </div>
       
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto mt-3">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
              INDONESIA
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
              PONDOK INDAH OFFICE <br />
              TOWER 15TH FLOOR JL <br />
              SULTAN ISKANDAR MUDA <br />
              KAVLING VTA JAKARTA 12310
            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">INDIA</h2>
            <p className="text-gray-600 mb-4 font-sans">
              PB/13, INDRAPURI MDANLAL <br />
              DHINGRA ROAD <br />
              PANCHPAKHADI, THEN (W) <br />
              MUMBAI 40006
            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
              BANGLADESH OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
              VERTEX SHIPPING LINES, <br />
              JIBAN BIMA BHABAN, <br />
              4TH FLOOR, <br />
              56 AGRABAD, CHATTOGRAM.
            </p>
          </div>
        </div>
       
      </div>

      <div className="max-w-[1240px] mx-auto mt-5">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
              REPRESENTATIVE OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            TAIWAN(CHINA) <br />
            HONGKONG(CHINA) <br />
            MALAYSIA <br />
            SOUTH KOREA <br />
            GERMANY <br />
            BELGIUM <br />
            ITALY <br />
            SPAIN <br />
            TURKIYE <br />
            COSTA RICA <br />
            DOMINICA REPUBLIC <br />
            MEXICO <br />
            PANAMA <br />
            BRAZIL <br />
            VENEZUELA <br />
            AUSTRALIA <br />
            CANADA <br />
            </p>
          </div>
        </div>
      </div>

      <div className="w-full my-7">
        <form
          className="max-w-[1240px] mx-auto p-6 bg-white rounded-lg shadow-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          {loadingData && (
            <div className="flex justify-center items-center">
              <BarLoader color="#004a93" loading={loadingData} size={20} />
            </div>
          )}

          {successData && (
            <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
              <CheckCircleFilled
                style={{
                  fontSize: '22px',
                  color: '#50C878',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />
              {successData}
            </h5>
          )}

          {errorData && (
            <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
              <ExclamationCircleOutlined
                style={{
                  fontSize: '20px',
                  color: '#FAA0A0',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />{' '}
              {errorData}
            </h5>
          )}
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2 text-gray-800">
              Name:
            </label>
            <input
              type="text"
              id="name"
              {...register('name', { required: 'Name is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            />
            {errors.name && (
              <span className="text-red-500">{errors.name.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2 text-gray-800">
              Email:
            </label>
            <input
              type="email"
              id="email"
              {...register('email', { required: 'Email is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            />
            {errors.email && (
              <span className="text-red-500">{errors.email.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="subject" className="block mb-2 text-gray-800">
              Subject:
            </label>
            <input
              type="text"
              id="subject"
              {...register('subject', { required: 'Subject is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            />
            {errors.subject && (
              <span className="text-red-500">{errors.subject.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block mb-2 text-gray-800">
              Message:
            </label>
            <textarea
              id="message"
              {...register('message', { required: 'Message is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            ></textarea>
            {errors.message && (
              <span className="text-red-500">{errors.message.message}</span>
            )}
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 mt-4 font-semibold text-white bg_color_new rounded-md hover:bg-sky-700"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contact
