import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Autosuggest from 'react-autosuggest'

const Schedule = () => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm()

  const placeData = [
    {
      placeOfLoading: 'VENEZIA ; IT ; ITVCE',
      placeOfDischarge: ['CHITTAGONG ; BD ; BDCGP', 'MONGLA ; BD ; BDCGP'],
    },
    {
      placeOfLoading: 'MARIBOR ; SI ; SIMBX',
      placeOfDischarge: ['DHAKA ; BD ; BDDAC', 'PANGAON, C ; BD ; BDPGN'],
    },
  ]

  const getPlaceOfLoadingSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    if (inputLength === 0) {
      return []
    }

    return placeData
      .filter((place) =>
        place.placeOfLoading.toLowerCase().includes(inputValue)
      )
      .map((place) => place.placeOfLoading)
  }

  const getPlaceOfDischargeSuggestions = (value, selectedPlaceOfLoading) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    if (inputLength === 0 || !selectedPlaceOfLoading) {
      return []
    }

    const selectedPlace = placeData.find(
      (place) => place.placeOfLoading === selectedPlaceOfLoading
    )

    if (!selectedPlace) {
      return []
    }

    return selectedPlace.placeOfDischarge.filter((discharge) =>
      discharge.toLowerCase().includes(inputValue)
    )
  }

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>

  const selectedPlaceOfLoading = watch('placeOfLoading')

  const onSubmit = (data) => {
    console.log(data)
    reset()

    setFormSubmitted(true)
  }

  return (
    <div className="w-full py-16 text-white px-4 bg-slate-100" id="schedule">
      <div className="max-w-[1240px] mx-auto mb-10">
        <div className="text-center">
          <h3 className="text-3xl text-black">Schedule</h3>
          <p className="my-5 text-md text-center text-black">
            Schedule and routing finder.
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="lg:col-span-2 my-4">
        <div className="my-3">
          <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0">
            <Controller
              name="placeOfLoading"
              control={control}
              defaultValue=""
              rules={{ required: 'Place of loading is required.' }}
              render={({ field }) => (
                <Autosuggest
                  suggestions={getPlaceOfLoadingSuggestions(field.value)}
                  onSuggestionsFetchRequested={() => {}}
                  onSuggestionsClearRequested={() => {}}
                  getSuggestionValue={(suggestion) => suggestion}
                  renderSuggestion={renderSuggestion}
                  inputProps={{
                    className:
                      'p-3 min-w-[320px] h-full rounded-md text-black text-center',
                    placeholder: 'Place of Loading',
                    value: field.value,
                    onChange: (event, { newValue }) => {
                      field.onChange(newValue)
                    },
                  }}
                />
              )}
            />
          </div>
          {errors.placeOfLoading && (
            <p className="text-red-500 text-md pt-2 text-center">
              {errors.placeOfLoading.message}
            </p>
          )}
        </div>

        <div className="my-3">
          <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0">
            <Controller
              name="placeOfDischarge"
              control={control}
              defaultValue=""
              rules={{ required: 'Place of discharge is required.' }}
              render={({ field }) => (
                <Autosuggest
                  suggestions={getPlaceOfDischargeSuggestions(
                    field.value,
                    selectedPlaceOfLoading // Use the selected placeOfLoading from the form data
                  )}
                  onSuggestionsFetchRequested={() => {}}
                  onSuggestionsClearRequested={() => {}}
                  getSuggestionValue={(suggestion) => suggestion}
                  renderSuggestion={renderSuggestion}
                  inputProps={{
                    className:
                      'p-3 min-w-[320px] h-full rounded-md text-black text-center',
                    placeholder: 'Place of Discharge',
                    value: field.value,
                    onChange: (event, { newValue }) => {
                      field.onChange(newValue)
                    },
                  }}
                />
              )}
            />
          </div>
          {errors.placeOfDischarge && (
            <p className="text-red-500 text-md pt-2 text-center">
              {errors.placeOfDischarge.message}
            </p>
          )}
        </div>

        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="bg_color_new text-white rounded-md font-medium min-w-[320px] h-full px-4 py-2"
          >
            Search
          </button>
        </div>
      </form>

      {formSubmitted ? (
        <div className="text-center my-4 text-red-500">
          This service is temporarily unavailable due to heavy maintenance work
          going on. Please try again later. <br />
          <p className="text-gray-600">
            For any further emergency queries, please do not hesitate to contact
            us at
            <br />
            info@transpacificline.com
          </p>
        </div>
      ) : null}
    </div>
  )
}

export default Schedule
