import React from 'react'
import { MdOutlineCancel } from 'react-icons/md'
import Button from './Button'

const UserProfile = ({ user }) => {
  return (
    <div className="nav-item absolute right-1 xs:right-1 sm:right-1 md:right-2 lg:right-2 top-12 bg-white dark:bg-[#42464D] p-4 rounded-lg w-72 xs:w-72 sm:w-72 md:w-96 lg:w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200"></p>
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-2 items-center pb-6">
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">
            {user?.name}
          </p>
          {user?.role === 'admin' && (
            <p className="text-gray-500 text-sm dark:text-gray-400">
              {' '}
              Administrator{' '}
            </p>
          )}

          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">
            {user?.email}
          </p>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
