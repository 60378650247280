import React from 'react';
import { SiEquinixmetal } from 'react-icons/si';
import { GoContainer } from 'react-icons/go';
import { LiaShipSolid } from 'react-icons/lia';


const Services = () => {
  return (
    <div className="w-full bg-white py-20 px-4" id="service">
      <div className="max-w-[1240px] mx-auto mb-20">
        <div className="text-center">
          <h3 className="text-3xl">Services</h3>
          <p className="my-5 text-md text-center">
            Transpacific line offers secure non-vessel operating common carriers
            (NVOCC) services for container trading and bulk chartering. Our
            extensive network extends from China in the east to the Persian Gulf
            in the west, covering South East Asia and the Indian Sub-Continent
            destinations as well.
          </p>
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8 ">
        <div className="w-full shadow-xl flex flex-col my-4 rounded-lg hover:scale-105 duration-300">
          <img src='https://images.pexels.com/photos/12530465/pexels-photo-12530465.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' style={{
            width: '100%',
            height: 240
          }} alt="NVOCC" className="w-full mb-4 rounded-t-md" />
          <div className="p-4">
          <div className="flex justify-center items-center mb-1">
            <SiEquinixmetal size={40} color="#28abe2" />
          </div>
          <h2 className="text-xl font-bold text-center py-4">
            Non-Vessel Operating Common Carrier (NVOCC)
          </h2>
          <div className="text-center font-medium">
            <p className="text-gray-600">
              At Transpacific line, we specialize in shipping a wide range of
              cargo, including chemicals, machinery, food items, packages,
              vehicles, pharmaceuticals, and notably, HMS scrap metals. Our
              diverse fleet of containers, such as dry containers, reefers, open
              tops, and flat racks, ensures secure and efficient transportation
              for these goods.
            </p>
          </div>
          </div>
        </div>

        <div className="w-full shadow-xl bg-gray-100 flex flex-col md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
          <img src="https://images.pexels.com/photos/753331/pexels-photo-753331.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" style={{
            width: '100%',
            height: 240
          }} alt="Container" className="w-full mb-4 rounded-t-md" />
          <div className="p-4">
          <div className="flex justify-center items-center mb-1">
            <GoContainer size={40} color="#28abe2" />
          </div>
          <h2 className="text-xl font-bold text-center py-4">
            Container solutions
          </h2>
          <div className="text-center font-medium">
            <p className="text-gray-600">
              At Transpacific line, we excel in the field of container trading,
              offering a comprehensive range of container solutions to meet the
              diverse needs of our clients. Our expertise and industry knowledge
              enable us to facilitate seamless container trading operations,
              ensuring efficient and reliable transportation of goods across
              various destinations.
            </p>
          </div>
          </div>
        </div>

        <div className="w-full shadow-xl flex flex-col my-4 rounded-lg hover:scale-105 duration-300">
          <img src="https://images.pexels.com/photos/9541901/pexels-photo-9541901.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" style={{
            width: '100%',
            height: 240
          }} alt="Shipment" className="w-full mb-4 rounded-t-md" />
          <div className="p-4">
          <div className="flex justify-center items-center mb-1">
            <LiaShipSolid size={40} color="#28abe2" />
          </div>
          <h2 className="text-xl font-bold text-center py-4">Shipment</h2>
          <div className="text-center font-medium">
            <p className="text-gray-600">
              At Transpacific line, we specialize in ship chartering services,
              providing unique and tailored solutions for the transportation of
              goods by chartering vessels of various types and sizes. Our
              extensive network and industry expertise allow us to facilitate
              efficient and cost-effective ship chartering arrangements that
              meet the specific requirements of our clients.
            </p>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
