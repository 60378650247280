import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import './style.css'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import BarLoader from 'react-spinners/BarLoader'
import logo from '../assets/logo-dark.png'
import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons'
import { useForm } from 'react-hook-form'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')

  const [activeTab, setActiveTab] = useState('user')

  const handleTabClick = (tab) => {
    setActiveTab(tab)
    setError('')
    setSuccess('')
  }

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [showPassword, setShowPassword] = useState(false)

  const onSubmit = async (data) => {
    setError('')
    setSuccess('')
    const { email, password } = data

    if (email.length > 0 && password.length > 0) {
      let logData = {
        email,
        password,
      }

      try {
        setLoading(true)
        const { data } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}login`,
          logData
        )
        const { message, ...rest } = data
        setError('')
        setSuccess('Login success! Redirecting...')
        setTimeout(() => {
          dispatch({ type: 'LOGIN', payload: rest })
          Cookies.set('user', JSON.stringify(rest), {
            expires: 30,
          })

          setSuccess('')
          navigate('/admin/categories')
        }, 1500)
        setLoading(false)
        reset()
      } catch (error) {
        setLoading(false)
        setSuccess('')
        setError(error.response.data.message)
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <div className="grid h-screen place-items-center">
      <div className="w-full p-6 m-auto  rounded-md shadow-sm shadow-purple-300 sm:max-w-xl lg:max-w-xl">
        <section className="hero container max-w-screen-lg mx-auto">
          <img
            className="mx-auto"
            alt="branding logo"
            src={logo}
            style={{
              width: '90px',
            }}
          />
        </section>
        <p className="text-center text-lg">Admin login</p>
        <div className="flex items-center justify-center h-[20px] my-2">
          {loading && <BarLoader color="#0E86D4" loading={loading} size={20} />}

          {success && (
            <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
              <CheckCircleFilled
                style={{
                  fontSize: '22px',
                  color: '#50C878',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />
              {success}
            </h5>
          )}
          {error && (
            <p className="text-center auth_error_success my-3 text-red-400 d-flex justify-content-center align-items-center">
              {' '}
              <ExclamationCircleOutlined
                style={{
                  fontSize: '20px',
                  color: '#FAA0A0',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />{' '}
              {error}
            </p>
          )}
        </div>

        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formInput mb-6">
              <input
                type="text"
                className="shadow appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                placeholder="Email"
                name="email"
                id="email"
                {...register('email', {
                  required: 'Email is required',
                })}
              />
              {errors.email && (
                <p className="text-red-500 text-xs py-2">
                  {errors.email?.message}
                </p>
              )}
            </div>

            <div className="formInput">
              <div className="mb-4 password_2 block relative">
                <input
                  {...register('password', {
                    required: 'Password is required',
                  })}
                  type={showPassword ? 'text' : 'password'}
                  className="shadow appearance-none block w-full  text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="password"
                  placeholder="Password"
                />
                <div
                  className="icon_button absolute right-4"
                  style={{
                    top: '8px',
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>

                {errors.password && (
                  <p className="text-red-500 text-xs py-2">
                    {errors.password.message}
                  </p>
                )}
              </div>
            </div>

            <div className="mt-6">
              <button className="w-full px-4 py-2 tracking-wide text-white font-bold transition-colors duration-200 transform bg-blue-700 rounded-md hover:bg-blue-800 focus:outline-none focus:bg-blue-900">
                Login
              </button>
            </div>
          </form>
        </>
      </div>
    </div>
  )
}

export default Login
