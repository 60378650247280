import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'
import { useStateContext } from '../components/contexts/ContextProvider'
import AdminSidebar from '../components/sidebar/AdminSidebar'
import { useForm } from 'react-hook-form'
import {
  CheckCircleFilled,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import BarLoader from 'react-spinners/BarLoader'
import NavbarAdmin from '../components/nav/Navbar'
import {
  addShippingBL,
  deleteShippingBl,
  getAllShippings,
  updateBlNumberFrontDirect,
} from '../components/functions/shipping'
const cancelIcon = require('../assets/cancel-icon.png')

const AdminShipping = () => {
  const { user } = useSelector((state) => ({ ...state }))

  const [userData, setUserData] = useState()
  const dispatch = useDispatch()
  const navigation = useNavigate()
  const { activeMenu } = useStateContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const [editIsOpen, setEditIsOpen] = useState(false)

  const [showDataModal, setShowDataModal] = useState(false)

  const [loadingData, setLoadingData] = useState(false)

  const [selectedDataEdit, setSelectedDataEdit] = useState(null)

  const [data, setData] = useState([])
  const [successData, setSuccessData] = useState('')
  const [errorData, setErrorData] = useState('')

  const loadData = () => {
    setLoading(true)
    setError('')
    setSuccess('')
    getAllShippings(user?.token)
      .then((res) => {
        if (res === 'No bl number found.') {
          setLoading(false)
          return setError('No BL found')
        }
        if (res.status === 'ok') {
          setData(res.data.data)
          setLoading(false)
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        setLoading(false)
        console.log(err)
      })
  }

  useEffect(() => {
    loadData()
  }, [])

  const closeModal = () => {
    setShowDataModal(false)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  const {
    register: registerCat,
    formState: { errors: errorsCat },
    handleSubmit: handleDataSubmit,
    reset: resetData,
  } = useForm({
    mode: 'onBlur',
  })

  const logout = () => {
    Cookies.remove('user')
    dispatch({
      type: 'LOGOUT',
      payload: null,
    })
    navigation('/login')
  }

  const handleSubmitForm = (data) => {
    let newdata = {
      blNumber: data.blNumber,
    }
    setLoadingData(true)
    addShippingBL(newdata, user?.token)
      .then((res) => {
        if (res.status === 'ok') {
          setErrorData('')
          setSuccessData('New BL Number for shipping added!')
          setTimeout(() => {
            setSuccessData('')
            loadData()
            resetData()
            setShowDataModal(false)
          }, 2000)
          setLoadingData(false)
        } else {
          setLoadingData(false)
          setErrorData(res)
        }
      })
      .catch((err) => {
        setLoadingData(false)
      })
  }

  const handleRemove = async (title, id) => {
    if (window.confirm('Are you sure you want to delete?')) {
      setLoading(true)
      try {
        await deleteShippingBl(id, user?.token)
        toast.success(`"${title}" is deleted`)
        setLoading(false)
        window.location.reload()
      } catch (error) {
        setLoading(false)
        toast.error(error.response.data.message)
      }
    }
  }

  const closeEditModal = () => {
    setEditIsOpen(false)
  }

  const handleEdit = (id) => {
    const dataFound = data.find((data) => data._id === id)

    setSelectedDataEdit(dataFound)
    setEditIsOpen(true)
  }

  useEffect(() => {
    if (selectedDataEdit) {
      setValue('blNumber', selectedDataEdit.blNumber)
    }
  }, [selectedDataEdit, setValue])

  const editBLData = (data) => {
    try {
      const newData = {
        blNumber: data.blNumber,
      }

      const dataIdFound = selectedDataEdit._id

      setLoadingData(true)
      updateBlNumberFrontDirect(dataIdFound, newData, user?.token)
        .then((res) => {
          if (res.status === 'ok') {
            setErrorData('')
            setSuccessData('BL Number updated!')
            setTimeout(() => {
              setSuccessData('')
              loadData()
              setEditIsOpen(false)
            }, 3000)
            setLoadingData(false)
          } else {
            setLoadingData(false)
            setErrorData(res)
          }
        })
        .catch((err) => {
          setLoadingData(false)
        })
    } catch (error) {
      setLoadingData(false)
      setSuccessData('')
      setSuccessData(error.response.data.message)
    }
  }
  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 sidebar dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <AdminSidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? 'dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  '
            : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
        }
      >
        <div className="md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
          <NavbarAdmin />
        </div>
        <div className="m-2 md:m-10 mt-10 p-2 md:p-5 bg-white rounded-1xl custom_design">
          <p className="text-2xl tracking-tight text-slate-600">
            <span className="font-bold">Manage Shipping</span>
          </p>
          {loading && (
            <div className="flex justify-center items-center">
              <BarLoader color="#004a93" loading={loading} size={20} />
            </div>
          )}
          {success && (
            <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
              <CheckCircleFilled
                style={{
                  fontSize: '22px',
                  color: '#50C878',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />
              {success}
            </h5>
          )}
          {error && (
            <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
              <ExclamationCircleOutlined
                style={{
                  fontSize: '20px',
                  color: '#FAA0A0',
                  marginRight: '5px',
                  position: 'relative',
                  top: '-3px',
                }}
              />{' '}
              {error}
            </h5>
          )}

          <br />
          <button
            onClick={() => setShowDataModal(true)}
            className="new_role_btn"
          >
            Add new
          </button>

          {showDataModal && (
            <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50">
              <div className="bg-white rounded-lg shadow-lg sm:w-4/5 md:w-1/2 pb-2">
                <div className="bg-gray-100 border-b px-4 py-6 flex justify-between items-center rounded-lg">
                  <h3 className="font-semibold text-xl text-stone-600">
                    Add BL Number
                  </h3>

                  <div className="flex">
                    <button
                      onClick={closeModal}
                      className="text-black close-modal"
                    >
                      <img src={cancelIcon} alt="Cancel" />
                    </button>
                  </div>
                </div>

                <div className="p-6">
                  {loadingData && (
                    <div className="flex justify-center items-center">
                      <BarLoader
                        color="#004a93"
                        loading={loadingData}
                        size={20}
                      />
                    </div>
                  )}

                  {successData && (
                    <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                      <CheckCircleFilled
                        style={{
                          fontSize: '22px',
                          color: '#50C878',
                          marginRight: '5px',
                          position: 'relative',
                          top: '-3px',
                        }}
                      />
                      {successData}
                    </h5>
                  )}

                  {errorData && (
                    <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                      <ExclamationCircleOutlined
                        style={{
                          fontSize: '20px',
                          color: '#FAA0A0',
                          marginRight: '5px',
                          position: 'relative',
                          top: '-3px',
                        }}
                      />{' '}
                      {errorData}
                    </h5>
                  )}

                  <form onSubmit={handleDataSubmit(handleSubmitForm)}>
                    <div className="mb-4">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="blNumber"
                      >
                        BL Number
                      </label>
                      <input
                        {...registerCat('blNumber', {
                          required: 'BL number is required',
                        })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="blNumber"
                        type="text"
                        placeholder="BL Number"
                      />
                      {errorsCat.blNumber && (
                        <p className="text-red-500 text-xs py-2">
                          {errorsCat.blNumber.message}
                        </p>
                      )}
                    </div>

                    <div className="flex justify-start mt-10">
                      <button
                        className={`bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 rounded mr-10 px-10 ${
                          loadingData && 'ActionButton'
                        }`}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        onClick={closeModal}
                        className=" text-blue-800 font-bold py-2 px-10 rounded border-blue-800 border-1"
                        disabled={loadingData}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
              <div className="border-b-1 relative border-pos-settings"></div>

              {editIsOpen && (
                <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50">
                  <div className="bg-white rounded-lg shadow-lg w-1/2 pb-2">
                    <div className="bg-gray-100 border-b px-4 py-6 flex justify-between items-center rounded-lg">
                      <h3 className="font-semibold text-xl text-stone-600">
                        Update BL Number
                      </h3>
                      <button
                        onClick={closeEditModal}
                        className="text-black close-modal"
                      >
                        <img src={cancelIcon} alt="Cancel" />
                      </button>
                    </div>

                    <div className="p-6">
                      {loadingData && (
                        <div className="flex justify-center items-center">
                          <BarLoader
                            color="#004a93"
                            loading={loadingData}
                            size={20}
                          />
                        </div>
                      )}

                      {successData && (
                        <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                          <CheckCircleFilled
                            style={{
                              fontSize: '22px',
                              color: '#50C878',
                              marginRight: '5px',
                              position: 'relative',
                              top: '-3px',
                            }}
                          />
                          {successData}
                        </h5>
                      )}
                      {errorData && (
                        <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                          <ExclamationCircleOutlined
                            style={{
                              fontSize: '20px',
                              color: '#FAA0A0',
                              marginRight: '5px',
                              position: 'relative',
                              top: '-3px',
                            }}
                          />{' '}
                          {errorData}
                        </h5>
                      )}
                      <form onSubmit={handleSubmit(editBLData)}>
                        <div className="mb-4">
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="blNumber"
                          >
                            BL Number
                          </label>
                          <input
                            {...register('blNumber', {
                              required: 'BL Number is required',
                            })}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="blNumber"
                            type="text"
                            placeholder="BL Number"
                          />
                          {errors.name && (
                            <p className="text-red-500 text-xs py-2">
                              {errors.name.message}
                            </p>
                          )}
                        </div>

                        <div className="flex justify-start mt-10">
                          <button
                            type="submit"
                            className={`bg-blue-700 hover:bg-blue-800 text-white font-bold py-2 rounded mr-10 px-10 ${
                              loading && 'ActionButton'
                            }`}
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            onClick={closeEditModal}
                            className=" text-blue-800 font-bold py-2 px-10 rounded border-blue-800 border-1"
                            disabled={loading}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}

              <div className="pb-3 mt-6 bg-white">
                <div className="space-y-6">
                  <table className="w-full table-fixed">
                    <thead>
                      <tr className="bg-gray-200">
                        <th className="py-2 px-6 text-left text-sm">
                          BL NUMBER
                        </th>
                        <th className="py-2 px-6 text-left"></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data?.map((bl) => {
                          return (
                            <tr key={bl._id} className="bg-white rounded-lg">
                              <td className="py-2">{bl?.blNumber}</td>
                              <td className="py-2"></td>
                              <td className="py-2">
                                <div className="flex flex-col sm:flex-row sm:justify-end">
                                  <button
                                    className="border border-gray-200 hover:bg-gray-200 text-gray-700 py-2 px-1 mb-2 sm:mb-0 text-center"
                                    onClick={() => handleEdit(bl?._id)}
                                  >
                                    Edit BL
                                  </button>
                                  <Link
                                    to={`/admin/shipping/manage-bl/update-bl/${bl?._id}`}
                                    as={NavLink}
                                    className="border border-gray-200 hover:bg-gray-200 text-gray-700 py-2 px-1 mb-2 sm:mb-0 text-center flex justify-center items-center"
                                  >
                                    Manage BL{' '}
                                    <EditOutlined className="text-green-500 ml-1" />
                                  </Link>

                                  <button
                                    onClick={() =>
                                      handleRemove(bl?.blNumber, bl?._id)
                                    }
                                    className="border border-gray-200  hover:bg-gray-200 text-gray-700 py-2 px-1 mb-2 sm:mb-0"
                                  >
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminShipping
