import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const NoRedirect = () => {
  const [count, setCount] = useState(3)
  let history = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((currentCount) => --currentCount)
    }, 1000)
    // redirect once count is equal to 0
    count === 0 && history('/admin/categories')
    // cleanup
    return () => clearInterval(interval)
  }, [count, history])

  return (
    <div className="container-fluid p-5 text-center">
      <p className="text-success h6 my-2 d-flex justify-content-center align-items-center">
        <ExclamationCircleOutlined
          style={{
            fontSize: '25px',
            color: 'green',
            marginRight: '5px',
          }}
        />
        <span className="block mt-3">You are already logged In</span>
      </p>
      <p className="text-muted"> Redirecting you in {count} seconds</p>
    </div>
  )
}

export default NoRedirect
