import axios from 'axios'

export const addCategory = async (newdata, authtoken) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}category/create`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getAllCategories = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}categories`,
      {}
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateCategory = async (catIdFound, catData, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}update-category/${catIdFound}`,
      { catData },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const deleteCategory = async (id, authtoken) => {
  try {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}category-del/${id}`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
    return { status: 'ok' }
  } catch (error) {
    return error.response.data.message
  }
}
