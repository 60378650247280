import React from 'react'
import Typed from 'react-typed'
import backgroundImage from '../assets/bg.jpg'
import Navbar from './Navbar'
import { Link } from 'react-scroll'
import { IoIosArrowDown } from 'react-icons/io'
import logo from '../assets/logo.png'

const Hero = () => {
  const backgroundUrl = `url('https://res.cloudinary.com/dwn02nfdv/image/upload/v1691653138/silverseaexpress/silverseaexpress-pte-ltd.jpg')`
  return (
    <div
      className="text-white bg-fixed bg-cover bg-center overflow-hidden"
      style={{ backgroundImage: backgroundUrl }}
      id="home"
    >
      <Navbar />
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <div className="flex justify-center items-center">
          <img src={logo} alt="Logo" className="w-1/3" />
        </div>

        <h1 className="md:text-4xl sm:text-2xl text-2xl font-bold md:py-6 uppercase">
          Welcome to Transpacific Line
        </h1>
        <div className="flex justify-center items-center">
          <p className="md:text-2xl sm:text-md text-xl font-bold py-4">
            Explore the world of shipping with us
          </p>
        </div>

        <Typed
          className="md:text-2xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
          strings={['Packaging', 'Deliveries', 'Shipments', 'Cargo']}
          typeSpeed={120}
          backSpeed={140}
          loop
        />

        <div className="flex justify-center items-center">
          <Link
            to="about"
            className="w-14 h-14 rounded-full flex items-center justify-center font-medium my-6 mx-auto py-3 text-white border-2 transition-colors duration-1000 hover:transform hover:translate-y-1 hover:text-white cursor-pointer"
            smooth={true}
            duration={300}
            offset={-70}
          >
            <IoIosArrowDown size={24} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hero
