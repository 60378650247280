import axios from 'axios'

export const addShippingBL = async (newdata, authtoken) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}add-bl-number`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const addShippingDetails = async (id, newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}add-bl-details/${id}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateShippingDetails = async (
  id,
  newdata,
  blDetailsId,
  authtoken
) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}update-bl-details/${id}`,
      { newdata, blDetailsId },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const deleteContainer = async (id, blContainerId, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}del-container/${id}`,
      { blContainerId },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const deleteBlDetails = async (id, blDetailsId, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}del-bl-details/${id}`,
      { blDetailsId },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateContainerDetails = async (
  id,
  newdata,
  blContainerId,
  authtoken
) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}update-bl-container/${id}`,
      { newdata, blContainerId },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const addContainerShippingDetails = async (id, newdata, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}add-bl-container-details/${id}`,
      { newdata },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getAllShippings = async (authtoken) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}all-bl`,
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateShipping = async (catIdFound, catData, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}update-category/${catIdFound}`,
      { catData },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const deleteShippingBl = async (id, authtoken) => {
  try {
    axios.delete(`${process.env.REACT_APP_BACKEND_URL}bl-del/${id}`, {
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
    return { status: 'ok' }
  } catch (error) {
    return error.response.data.message
  }
}

export const getBlDetailsById = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}bl-by-id/${id}`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const getBlDetailsByIdTracking = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}track-bl/${id}`
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}

export const updateBlNumberFrontDirect = async (id, newData, authtoken) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}update-bl-number-direct/${id}`,
      { newData },
      {
        headers: {
          Authorization: `Bearer ${authtoken}`,
        },
      }
    )
    return { status: 'ok', data }
  } catch (error) {
    return error.response.data.message
  }
}
